import React, { useRef, useState, useCallback, FormEventHandler } from 'react'
import { useHistory } from "react-router-dom";
import { Alert, Form, Button, Row } from 'react-bootstrap';
import { useAuth } from '../../context/AuthContext';

export default function UpdateAccount() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmRef = useRef<HTMLInputElement>(null);
  const { currentUser, updateEmail, updatePassword, logout } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = useCallback((e): FormEventHandler<HTMLFormElement> => {
    e.preventDefault();

    if (passwordRef.current?.value !== confirmRef.current?.value) {
      setError('Passwords don\'t match');
      return e;
    }

    const promises = [];
    setLoading(true);
    setError('');

    if (emailRef.current?.value !== currentUser?.email) {
      promises.push(updateEmail(emailRef.current?.value));
    }
    if (passwordRef.current?.value) {
      promises.push(updatePassword(passwordRef.current?.value));
    }

    Promise.all(promises).then(() => {
      history.push('/')
    }).catch(({ message }) => {
      setError(message);
    }).finally(() => {
      setLoading(false);
    })
    return e;

  }, [currentUser, history, updateEmail, updatePassword]);

  return (
    <>
      <h4 className="text-center mb-3">Update Account</h4>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit} className="accountForm">
        <Form.Group id="emai">
          <Form.Label>Email</Form.Label>
          <Form.Control size="sm" type="email" ref={emailRef} required defaultValue={currentUser?.email} />
        </Form.Group>

        <Form.Group id="password">
          <Form.Label>Password</Form.Label>
          <Form.Control size="sm" type="password" ref={passwordRef} placeholder="Leave blank to keep current" />
        </Form.Group>

        <Form.Group id="confirm">
          <Form.Label>Confirm</Form.Label>
          <Form.Control size="sm" type="password" ref={confirmRef} placeholder="Leave blank to keep current" />
        </Form.Group>

        <Row>
          <Button size="sm" onClick={logout} variant="success">LOGOUT</Button>
          <Button size="sm" disabled={loading} type="submit">UPDATE</Button>
        </Row>
        </Form>
    </>
  )
}
